import { memo } from 'react';

import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
  single?: boolean;
}

function Logo({ single = false, sx }: LogoProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const singleLogo = (
    <svg xmlns="http://www.w3.org/2000/svg" width="180" height="60" viewBox="0 0 93 34" fill="none">
      <path
        d="M6.34483 10.3551C7.10101 10.3551 7.80402 10.4316 8.45387 10.5845C9.11552 10.7375 9.68266 10.9198 10.1553 11.1316C10.6397 11.3434 10.9823 11.5317 11.1832 11.6964L9.96032 13.6378C9.72401 13.426 9.29866 13.1907 8.68426 12.9319C8.06987 12.6612 7.38458 12.5259 6.6284 12.5259C5.80133 12.5259 5.09831 12.6907 4.51936 13.0201C3.94041 13.3378 3.65094 13.8026 3.65094 14.4144C3.65094 15.0262 3.94632 15.5145 4.53709 15.8793C5.13967 16.2323 5.94311 16.5264 6.94741 16.7617C7.75085 16.95 8.50113 17.203 9.19823 17.5207C9.90715 17.8383 10.4802 18.2796 10.9174 18.8443C11.3545 19.3974 11.5731 20.1386 11.5731 21.0681C11.5731 21.9153 11.3959 22.6389 11.0414 23.239C10.687 23.8273 10.2084 24.3038 9.60586 24.6686C9.00328 25.0216 8.3298 25.2804 7.58544 25.4452C6.84107 25.6099 6.08489 25.6922 5.3169 25.6922C4.41893 25.6922 3.60958 25.5981 2.88885 25.4099C2.16811 25.2334 1.55962 25.0216 1.06338 24.7745C0.578951 24.5156 0.224491 24.2921 0 24.1038L1.22289 21.9153C1.53008 22.2095 2.03224 22.5213 2.72934 22.8507C3.43826 23.1802 4.2417 23.3449 5.13967 23.3449C6.13215 23.3449 6.9356 23.139 7.54999 22.7272C8.16439 22.3036 8.47159 21.7565 8.47159 21.0858C8.47159 20.5799 8.3239 20.1739 8.02851 19.868C7.73313 19.5621 7.33732 19.315 6.84107 19.1267C6.34483 18.9385 5.80133 18.7738 5.21056 18.6326C4.64342 18.5031 4.0881 18.3325 3.5446 18.1207C3.01291 17.9089 2.52257 17.6383 2.07359 17.3089C1.63642 16.9676 1.28787 16.5558 1.02793 16.0734C0.767996 15.5792 0.638028 15.0027 0.638028 14.3438C0.638028 13.5084 0.903872 12.7965 1.43556 12.2082C1.96725 11.6082 2.66436 11.1493 3.52687 10.8316C4.38939 10.5139 5.32871 10.3551 6.34483 10.3551Z"
        fill="white"
      />
      <path
        d="M21.2975 23.1508C22.2191 23.1508 22.9635 23.0037 23.5306 22.7095C24.0977 22.4154 24.4522 22.2036 24.594 22.0742L26.1004 24.2803C25.9823 24.3862 25.6987 24.5568 25.2497 24.7921C24.8126 25.0157 24.2454 25.2216 23.5483 25.4099C22.863 25.5981 22.0832 25.6922 21.2089 25.6922C19.8737 25.6922 18.6331 25.3981 17.4871 24.8098C16.341 24.2097 15.4135 23.339 14.7045 22.1977C14.0074 21.0564 13.6589 19.6621 13.6589 18.0148C13.6589 16.3558 14.0074 14.9615 14.7045 13.832C15.4135 12.6907 16.341 11.8258 17.4871 11.2375C18.6331 10.6492 19.8737 10.3551 21.2089 10.3551C22.0714 10.3551 22.8394 10.461 23.5129 10.6728C24.1863 10.8728 24.7417 11.0963 25.1788 11.3434C25.616 11.5905 25.9055 11.7788 26.0473 11.9082L24.5231 14.1144C24.4286 14.0202 24.2454 13.879 23.9737 13.6908C23.7137 13.4908 23.3593 13.3084 22.9103 13.1436C22.4613 12.9789 21.9237 12.8966 21.2975 12.8966C20.5413 12.8966 19.8029 13.1025 19.0821 13.5143C18.3732 13.9143 17.7883 14.4968 17.3275 15.2616C16.8667 16.0264 16.6363 16.9441 16.6363 18.0148C16.6363 19.0856 16.8667 20.0092 17.3275 20.7858C17.7883 21.5506 18.3732 22.1389 19.0821 22.5507C19.8029 22.9507 20.5413 23.1508 21.2975 23.1508Z"
        fill="white"
      />
      <path
        d="M40.1384 25.2687V22.4448C40.0439 22.7389 39.7662 23.1449 39.3054 23.6626C38.8565 24.1803 38.2361 24.6509 37.4445 25.0745C36.6647 25.4863 35.7431 25.6922 34.6797 25.6922C33.3328 25.6922 32.1099 25.3746 31.0111 24.7392C29.9241 24.1038 29.0556 23.2155 28.4058 22.0742C27.7678 20.9211 27.4488 19.5738 27.4488 18.0325C27.4488 16.4911 27.7678 15.1498 28.4058 14.0085C29.0556 12.8554 29.9241 11.9612 31.0111 11.3258C32.1099 10.6786 33.3328 10.3551 34.6797 10.3551C35.7313 10.3551 36.6411 10.5433 37.4091 10.9198C38.1889 11.2964 38.8092 11.7317 39.27 12.2259C39.7426 12.7201 40.0203 13.1436 40.103 13.4966V10.7787H43.1513V25.2687H40.1384ZM30.4971 18.0325C30.4971 19.0797 30.7275 19.9857 31.1883 20.7505C31.6491 21.5153 32.2458 22.1095 32.9783 22.533C33.7227 22.9448 34.5143 23.1508 35.3532 23.1508C36.2512 23.1508 37.0546 22.939 37.7635 22.5154C38.4725 22.0918 39.0337 21.4976 39.4472 20.7328C39.8608 19.9562 40.0675 19.0561 40.0675 18.0325C40.0675 17.0088 39.8608 16.1146 39.4472 15.3498C39.0337 14.5732 38.4725 13.9732 37.7635 13.5496C37.0546 13.1142 36.2512 12.8966 35.3532 12.8966C34.5143 12.8966 33.7227 13.1084 32.9783 13.5319C32.2458 13.9555 31.6491 14.5497 31.1883 15.3145C30.7275 16.0793 30.4971 16.9853 30.4971 18.0325Z"
        fill="white"
      />
      <path d="M49.7709 25.2687H46.6693V0.983337H49.7709V25.2687Z" fill="white" />
      <path
        d="M53.8408 25.2687V10.7787H56.9246V25.2687H53.8408ZM55.4181 5.81922C54.8628 5.81922 54.3843 5.62508 53.9826 5.2368C53.5927 4.83675 53.3977 4.36022 53.3977 3.80721C53.3977 3.2542 53.5927 2.77768 53.9826 2.37763C54.3843 1.97758 54.8628 1.77755 55.4181 1.77755C55.7844 1.77755 56.1211 1.87168 56.4283 2.05994C56.7474 2.2482 57.0014 2.49529 57.1904 2.80121C57.3795 3.09536 57.474 3.4307 57.474 3.80721C57.474 4.36022 57.2731 4.83675 56.8714 5.2368C56.4697 5.62508 55.9853 5.81922 55.4181 5.81922Z"
        fill="white"
      />
      <path
        d="M69.0645 10.3551C70.0216 10.3551 70.9491 10.5551 71.8471 10.9551C72.7568 11.3552 73.5012 11.9906 74.0802 12.8613C74.6709 13.7202 74.9663 14.8439 74.9663 16.2323V25.2687H71.8471V16.8323C71.8471 15.3969 71.5103 14.3556 70.8368 13.7084C70.1634 13.0495 69.2949 12.7201 68.2316 12.7201C67.5345 12.7201 66.861 12.9201 66.2111 13.3201C65.5731 13.7084 65.0473 14.2497 64.6338 14.9439C64.2203 15.6263 64.0135 16.4029 64.0135 17.2736V25.2687H60.9297V10.7787H64.0135V13.3554C64.1198 12.9554 64.4093 12.52 64.8819 12.0494C65.3545 11.5788 65.9571 11.1787 66.6897 10.8493C67.4222 10.5198 68.2138 10.3551 69.0645 10.3551Z"
        fill="white"
      />
      <path
        d="M85.2373 33.0167C84.0912 33.0167 83.0515 32.899 82.1181 32.6637C81.1965 32.4284 80.3871 32.093 79.69 31.6577C79.0047 31.2341 78.4553 30.734 78.0418 30.1575L80.0622 28.2337C80.2867 28.5396 80.588 28.8632 80.9661 29.2044C81.356 29.5574 81.8818 29.8575 82.5434 30.1045C83.2051 30.3516 84.0676 30.4752 85.131 30.4752C86.1707 30.4752 87.051 30.2516 87.7717 29.8045C88.4924 29.3692 89.0419 28.775 89.42 28.0219C89.798 27.2689 89.9871 26.4217 89.9871 25.4805V25.0039H93V25.6746C93 27.2748 92.6514 28.6161 91.9543 29.6986C91.2691 30.7929 90.3416 31.6165 89.1718 32.1695C88.0021 32.7343 86.6906 33.0167 85.2373 33.0167ZM89.9871 25.2687V22.4448C89.8926 22.7389 89.6149 23.1449 89.1541 23.6626C88.7051 24.1803 88.0848 24.6509 87.2932 25.0745C86.5134 25.4863 85.5918 25.6922 84.5284 25.6922C83.1815 25.6922 81.9586 25.3746 80.8597 24.7392C79.7727 24.1038 78.9043 23.2155 78.2545 22.0742C77.6164 20.9211 77.2974 19.5738 77.2974 18.0325C77.2974 16.4911 77.6164 15.1498 78.2545 14.0085C78.9043 12.8554 79.7727 11.9612 80.8597 11.3258C81.9586 10.6786 83.1815 10.3551 84.5284 10.3551C85.58 10.3551 86.4898 10.5433 87.2578 10.9198C88.0376 11.2964 88.6579 11.7317 89.1187 12.2259C89.5913 12.7201 89.8689 13.1436 89.9516 13.4966V10.7787H93V25.2687H89.9871ZM80.3458 18.0325C80.3458 19.0797 80.5762 19.9857 81.037 20.7505C81.4978 21.5153 82.0945 22.1095 82.827 22.533C83.5714 22.9448 84.363 23.1508 85.2019 23.1508C86.0998 23.1508 86.9033 22.939 87.6122 22.5154C88.3211 22.0918 88.8824 21.4976 89.2959 20.7328C89.7094 19.9562 89.9162 19.0561 89.9162 18.0325C89.9162 17.0088 89.7094 16.1146 89.2959 15.3498C88.8824 14.5732 88.3211 13.9732 87.6122 13.5496C86.9033 13.1142 86.0998 12.8966 85.2019 12.8966C84.363 12.8966 83.5714 13.1084 82.827 13.5319C82.0945 13.9555 81.4978 14.5497 81.037 15.3145C80.5762 16.0793 80.3458 16.9853 80.3458 18.0325Z"
        fill="white"
      />
    </svg>
  );

  const fullLogo = (
    <svg xmlns="http://www.w3.org/2000/svg" width="180" height="60" viewBox="0 0 93 34" fill="none">
      <path
        d="M6.34483 10.3551C7.10101 10.3551 7.80402 10.4316 8.45387 10.5845C9.11552 10.7375 9.68266 10.9198 10.1553 11.1316C10.6397 11.3434 10.9823 11.5317 11.1832 11.6964L9.96032 13.6378C9.72401 13.426 9.29866 13.1907 8.68426 12.9319C8.06987 12.6612 7.38458 12.5259 6.6284 12.5259C5.80133 12.5259 5.09831 12.6907 4.51936 13.0201C3.94041 13.3378 3.65094 13.8026 3.65094 14.4144C3.65094 15.0262 3.94632 15.5145 4.53709 15.8793C5.13967 16.2323 5.94311 16.5264 6.94741 16.7617C7.75085 16.95 8.50113 17.203 9.19823 17.5207C9.90715 17.8383 10.4802 18.2796 10.9174 18.8443C11.3545 19.3974 11.5731 20.1386 11.5731 21.0681C11.5731 21.9153 11.3959 22.6389 11.0414 23.239C10.687 23.8273 10.2084 24.3038 9.60586 24.6686C9.00328 25.0216 8.3298 25.2804 7.58544 25.4452C6.84107 25.6099 6.08489 25.6922 5.3169 25.6922C4.41893 25.6922 3.60958 25.5981 2.88885 25.4099C2.16811 25.2334 1.55962 25.0216 1.06338 24.7745C0.578951 24.5156 0.224491 24.2921 0 24.1038L1.22289 21.9153C1.53008 22.2095 2.03224 22.5213 2.72934 22.8507C3.43826 23.1802 4.2417 23.3449 5.13967 23.3449C6.13215 23.3449 6.9356 23.139 7.54999 22.7272C8.16439 22.3036 8.47159 21.7565 8.47159 21.0858C8.47159 20.5799 8.3239 20.1739 8.02851 19.868C7.73313 19.5621 7.33732 19.315 6.84107 19.1267C6.34483 18.9385 5.80133 18.7738 5.21056 18.6326C4.64342 18.5031 4.0881 18.3325 3.5446 18.1207C3.01291 17.9089 2.52257 17.6383 2.07359 17.3089C1.63642 16.9676 1.28787 16.5558 1.02793 16.0734C0.767996 15.5792 0.638028 15.0027 0.638028 14.3438C0.638028 13.5084 0.903872 12.7965 1.43556 12.2082C1.96725 11.6082 2.66436 11.1493 3.52687 10.8316C4.38939 10.5139 5.32871 10.3551 6.34483 10.3551Z"
        fill="white"
      />
      <path
        d="M21.2975 23.1508C22.2191 23.1508 22.9635 23.0037 23.5306 22.7095C24.0977 22.4154 24.4522 22.2036 24.594 22.0742L26.1004 24.2803C25.9823 24.3862 25.6987 24.5568 25.2497 24.7921C24.8126 25.0157 24.2454 25.2216 23.5483 25.4099C22.863 25.5981 22.0832 25.6922 21.2089 25.6922C19.8737 25.6922 18.6331 25.3981 17.4871 24.8098C16.341 24.2097 15.4135 23.339 14.7045 22.1977C14.0074 21.0564 13.6589 19.6621 13.6589 18.0148C13.6589 16.3558 14.0074 14.9615 14.7045 13.832C15.4135 12.6907 16.341 11.8258 17.4871 11.2375C18.6331 10.6492 19.8737 10.3551 21.2089 10.3551C22.0714 10.3551 22.8394 10.461 23.5129 10.6728C24.1863 10.8728 24.7417 11.0963 25.1788 11.3434C25.616 11.5905 25.9055 11.7788 26.0473 11.9082L24.5231 14.1144C24.4286 14.0202 24.2454 13.879 23.9737 13.6908C23.7137 13.4908 23.3593 13.3084 22.9103 13.1436C22.4613 12.9789 21.9237 12.8966 21.2975 12.8966C20.5413 12.8966 19.8029 13.1025 19.0821 13.5143C18.3732 13.9143 17.7883 14.4968 17.3275 15.2616C16.8667 16.0264 16.6363 16.9441 16.6363 18.0148C16.6363 19.0856 16.8667 20.0092 17.3275 20.7858C17.7883 21.5506 18.3732 22.1389 19.0821 22.5507C19.8029 22.9507 20.5413 23.1508 21.2975 23.1508Z"
        fill="white"
      />
      <path
        d="M40.1384 25.2687V22.4448C40.0439 22.7389 39.7662 23.1449 39.3054 23.6626C38.8565 24.1803 38.2361 24.6509 37.4445 25.0745C36.6647 25.4863 35.7431 25.6922 34.6797 25.6922C33.3328 25.6922 32.1099 25.3746 31.0111 24.7392C29.9241 24.1038 29.0556 23.2155 28.4058 22.0742C27.7678 20.9211 27.4488 19.5738 27.4488 18.0325C27.4488 16.4911 27.7678 15.1498 28.4058 14.0085C29.0556 12.8554 29.9241 11.9612 31.0111 11.3258C32.1099 10.6786 33.3328 10.3551 34.6797 10.3551C35.7313 10.3551 36.6411 10.5433 37.4091 10.9198C38.1889 11.2964 38.8092 11.7317 39.27 12.2259C39.7426 12.7201 40.0203 13.1436 40.103 13.4966V10.7787H43.1513V25.2687H40.1384ZM30.4971 18.0325C30.4971 19.0797 30.7275 19.9857 31.1883 20.7505C31.6491 21.5153 32.2458 22.1095 32.9783 22.533C33.7227 22.9448 34.5143 23.1508 35.3532 23.1508C36.2512 23.1508 37.0546 22.939 37.7635 22.5154C38.4725 22.0918 39.0337 21.4976 39.4472 20.7328C39.8608 19.9562 40.0675 19.0561 40.0675 18.0325C40.0675 17.0088 39.8608 16.1146 39.4472 15.3498C39.0337 14.5732 38.4725 13.9732 37.7635 13.5496C37.0546 13.1142 36.2512 12.8966 35.3532 12.8966C34.5143 12.8966 33.7227 13.1084 32.9783 13.5319C32.2458 13.9555 31.6491 14.5497 31.1883 15.3145C30.7275 16.0793 30.4971 16.9853 30.4971 18.0325Z"
        fill="white"
      />
      <path d="M49.7709 25.2687H46.6693V0.983337H49.7709V25.2687Z" fill="white" />
      <path
        d="M53.8408 25.2687V10.7787H56.9246V25.2687H53.8408ZM55.4181 5.81922C54.8628 5.81922 54.3843 5.62508 53.9826 5.2368C53.5927 4.83675 53.3977 4.36022 53.3977 3.80721C53.3977 3.2542 53.5927 2.77768 53.9826 2.37763C54.3843 1.97758 54.8628 1.77755 55.4181 1.77755C55.7844 1.77755 56.1211 1.87168 56.4283 2.05994C56.7474 2.2482 57.0014 2.49529 57.1904 2.80121C57.3795 3.09536 57.474 3.4307 57.474 3.80721C57.474 4.36022 57.2731 4.83675 56.8714 5.2368C56.4697 5.62508 55.9853 5.81922 55.4181 5.81922Z"
        fill="white"
      />
      <path
        d="M69.0645 10.3551C70.0216 10.3551 70.9491 10.5551 71.8471 10.9551C72.7568 11.3552 73.5012 11.9906 74.0802 12.8613C74.6709 13.7202 74.9663 14.8439 74.9663 16.2323V25.2687H71.8471V16.8323C71.8471 15.3969 71.5103 14.3556 70.8368 13.7084C70.1634 13.0495 69.2949 12.7201 68.2316 12.7201C67.5345 12.7201 66.861 12.9201 66.2111 13.3201C65.5731 13.7084 65.0473 14.2497 64.6338 14.9439C64.2203 15.6263 64.0135 16.4029 64.0135 17.2736V25.2687H60.9297V10.7787H64.0135V13.3554C64.1198 12.9554 64.4093 12.52 64.8819 12.0494C65.3545 11.5788 65.9571 11.1787 66.6897 10.8493C67.4222 10.5198 68.2138 10.3551 69.0645 10.3551Z"
        fill="white"
      />
      <path
        d="M85.2373 33.0167C84.0912 33.0167 83.0515 32.899 82.1181 32.6637C81.1965 32.4284 80.3871 32.093 79.69 31.6577C79.0047 31.2341 78.4553 30.734 78.0418 30.1575L80.0622 28.2337C80.2867 28.5396 80.588 28.8632 80.9661 29.2044C81.356 29.5574 81.8818 29.8575 82.5434 30.1045C83.2051 30.3516 84.0676 30.4752 85.131 30.4752C86.1707 30.4752 87.051 30.2516 87.7717 29.8045C88.4924 29.3692 89.0419 28.775 89.42 28.0219C89.798 27.2689 89.9871 26.4217 89.9871 25.4805V25.0039H93V25.6746C93 27.2748 92.6514 28.6161 91.9543 29.6986C91.2691 30.7929 90.3416 31.6165 89.1718 32.1695C88.0021 32.7343 86.6906 33.0167 85.2373 33.0167ZM89.9871 25.2687V22.4448C89.8926 22.7389 89.6149 23.1449 89.1541 23.6626C88.7051 24.1803 88.0848 24.6509 87.2932 25.0745C86.5134 25.4863 85.5918 25.6922 84.5284 25.6922C83.1815 25.6922 81.9586 25.3746 80.8597 24.7392C79.7727 24.1038 78.9043 23.2155 78.2545 22.0742C77.6164 20.9211 77.2974 19.5738 77.2974 18.0325C77.2974 16.4911 77.6164 15.1498 78.2545 14.0085C78.9043 12.8554 79.7727 11.9612 80.8597 11.3258C81.9586 10.6786 83.1815 10.3551 84.5284 10.3551C85.58 10.3551 86.4898 10.5433 87.2578 10.9198C88.0376 11.2964 88.6579 11.7317 89.1187 12.2259C89.5913 12.7201 89.8689 13.1436 89.9516 13.4966V10.7787H93V25.2687H89.9871ZM80.3458 18.0325C80.3458 19.0797 80.5762 19.9857 81.037 20.7505C81.4978 21.5153 82.0945 22.1095 82.827 22.533C83.5714 22.9448 84.363 23.1508 85.2019 23.1508C86.0998 23.1508 86.9033 22.939 87.6122 22.5154C88.3211 22.0918 88.8824 21.4976 89.2959 20.7328C89.7094 19.9562 89.9162 19.0561 89.9162 18.0325C89.9162 17.0088 89.7094 16.1146 89.2959 15.3498C88.8824 14.5732 88.3211 13.9732 87.6122 13.5496C86.9033 13.1142 86.0998 12.8966 85.2019 12.8966C84.363 12.8966 83.5714 13.1084 82.827 13.5319C82.0945 13.9555 81.4978 14.5497 81.037 15.3145C80.5762 16.0793 80.3458 16.9853 80.3458 18.0325Z"
        fill="white"
      />
    </svg>
  );

  return (
    <Link
      component={RouterLink}
      href="/"
      color="inherit"
      aria-label="go to homepage"
      sx={{ lineHeight: 0 }}
    >
      <Box
        sx={{
          width: single ? 93 : 110,
          lineHeight: 0,
          cursor: 'pointer',
          display: 'inline-flex',
          ...sx,
        }}
      >
        {single ? singleLogo : fullLogo}
      </Box>
    </Link>
  );
}

export default memo(Logo);

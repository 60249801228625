import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { bgBlur } from 'src/theme/css';
import HeaderShadow from '../common/header-shadow';
import { HEADER } from '../config-layout';
import Logo from './logo';
import { Button } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  headerOnDark: boolean;
};
export default function Header({ headerOnDark }: Props) {
  const theme = useTheme();
  const offset = useOffSetTop();
  const mdUp = useResponsive('up', 'md');

  // this component loads the current user's TalkJS Session
  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(headerOnDark && {
            color: 'common.white',
          }),
          ...(offset && {
            ...bgBlur({ color: theme.palette.background.default }),
            color: 'text.primary',
            height: {
              md: HEADER.H_DESKTOP - 16,
            },
          }),
          background: 'rgba(37, 108, 203, 1)',
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            height: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={10}>
            <Box sx={{ lineHeight: 0, position: 'relative' }}>
              <Logo />
              {/* <Link href="#" target="_blank" rel="noopener">
                <Label
                  color="info"
                  sx={{
                    ml: 13,
                    px: 0.5,
                    top: -4,
                    left: 1,
                    height: 20,
                    fontSize: 11,
                    cursor: 'pointer',
                    position: 'absolute',
                  }}
                >
                  beta
                </Label>
              </Link> */}
            </Box>
          </Stack>
          {mdUp && (
            <Button
              sx={{
                padding: '0px 32px',
                color: 'white',
                backgroundColor: 'initial',
                borderColor: 'rgb(255,255,255,0.5)',
                fontWeight: '700',
                // '&:hover': {
                //   backgroundColor: 'white',
                // },
              }}
              size="large"
              variant="outlined"
              fullWidth={mdUp ? undefined : true}
              onClick={() => {
                // document.querySelector('#scalingWork')!.scrollIntoView({ behavior: 'smooth' });
                // scroll to bottom
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
              }}
            >
              How Scaling works
            </Button>
          )}
          {/* {!mdUp && (
              <NavMobile
                data={[
                  { title: 'Account', path: paths.account.personal },
                  { title: 'Notification', path: paths.account.notification },
                  {
                    title: 'Message',
                    path: paths.account.notification,
                    render: () => <MessageNotificationItemList />,
                  },
                  ...navConfig,
                ]}
              />
            )} */}
        </Container>
      </Toolbar>

      {offset && <HeaderShadow />}
    </AppBar>
  );
}

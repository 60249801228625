import { lazy } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import MainLayout from 'src/layouts/main';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/home'));

export default function Router() {
  return useRoutes([
    {
      element: (
        <MainLayout>
          <Outlet />
        </MainLayout>
      ),
      children: [{ element: <IndexPage />, index: true }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
